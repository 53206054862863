.settlements__blockImage_XL {
  display: block;
  width: 110px;
  height: 110px;
}

.settlements__blockImage_S {
  display: block;
  width: 35px;
  height: 35px;
}

.settlements {
  &__actionButton {
    &__header.MuiTypography-root {
      font-size: 1rem;
      font-weight: 700;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-top: 30px;
      margin-bottom: 20px;
      padding-bottom: 6px;
      @media (min-width: 1200px) {
        margin-top: 100px;
        font-size: 1.2rem;
      }
    }
  }

  &__separator{
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  
  &__hide {
    &__below1500 {
      display: none;
      @media (min-width: 1500px) {
        display: block;
      }
    }
  }

  &__hide {
    &__over1500 {
      display: block;
      @media (min-width: 1500px) {
        display: none;
      }
    }
  }

  &__hide {
    &__below1200 {
      display: none;
      @media (min-width: 1200px) {
        display: block;
      }
    }
  }

  &__hide {
    &__over1200 {
      display: block;
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  &__positioning1700 {
    position: static;
    @media (min-width: 1700px) {
      position: absolute;
    }
  }

  &__positioning1200 {
    position: static !important;
    @media (min-width: 1200px) {
      position: absolute !important;
    }
  }

  &__firstLetter:first-letter {
    text-transform: uppercase
  }

  &__summary__footer {
    font-size: 18px !important;
    @media (min-width: 1200px) {
      font-size: 24px !important;
    }
  }

  &__status {
    padding-bottom: 15px;

    &__header.MuiTypography-root {
      font-weight: normal;
      line-height: 1.2;
    }
  }
}