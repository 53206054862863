//PRESETS
@import 'presets/fonts';
@import 'presets/reset';
@import 'presets/colors';
@import 'presets/mixins';
@import 'App';

//SHARED
@import './components/shared/Header';
@import './components/shared/SideBar';
@import './components/shared/Footer';
@import './components/shared/Content';
@import './components/shared/DimensionError';
@import './components/shared/formElements/CustomFormElements';
@import './components/shared/Settlements';
@import 'components/shared/search/Search';

//SHARED-UI
@import './components/shared/ui/Line';
@import './components/shared/ui/LocalLoader';
@import './components/shared/ui/GlobalLoader';

//MODALS
@import 'components/shared/modals/CustomModal';

//PAGES
@import './components/pages/Auth';
@import './components/pages/MainDash';
@import './components/pages/AdverseEvents';
@import './components/pages/AddUserTable';
@import './components/pages/HardwareOrders';
@import './components/pages/Settlements';
@import './components/pages/Reckonings';


.MuiFormControlLabel-root {
  margin-left: 0 !important;
}