.dynamicLeftBorder {
  border-left: 1px solid $mainDecorColor;
}

.dynamicLeftBorderInformation {
  border-left: 1px solid black;
}

.dynamicTopBorder {
  border-top: 1px solid $mainDecorColor;
}

.information-span {
  font-size: inherit;
  font-weight: bold;
}

.information-li {
  list-style-type: disc;
  margin-left: 60px;
}

.subMenuHeader {
  box-sizing: border-box;
  position: relative;
  height: 0;
  width: 150px;
  padding: 0;
  background-color: transparent;
  color: #ffffff;
  border-top: 15px solid $mainDecorColor;
  border-bottom: 15px solid $mainDecorColor;
  border-right: 15px solid transparent;
  font-weight: bold;
  text-align: center;
  line-height: 0;
}

.subMenuHeaderSmall {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 190px;
  padding: 0;
  background-color: transparent;
  color: #ffffff;
  border-top: 15px solid $mainDecorColor;
  border-bottom: 15px solid $mainDecorColor;
  border-right: 15px solid transparent;
  line-height: 0px;
  font-size: 12px;
  span {
    padding-left: 16px;
   
  }
}

.subMenuSidebar {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  border-left: 50px solid $mainDecorColor;
  border-bottom: 50px solid #ffffff;
  border-right: 50px solid $mainDecorColor;
  background: $mainDecorColor;
  height: auto;
  width: 100px;
  line-height: initial;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 16px 0;
}

.formButton {
  font-size: 12px !important;
  line-height: 13px !important;
  text-transform: lowercase !important;
  padding: 7px !important;
  margin: 5px !important;
  min-width: 105px !important;
}

.formRow:not(:first-child) {
  border-top: 1px solid $mainDecorColor;
}

@media screen and (max-width: 1199px) {
  .dynamicLeftBorder,
  .dynamicTopBorder {
    border: none;
  }
}

@media screen and (max-width: 899px) {
  .dynamicLeftBorderInformation {
    border: none;
  }
}