.search {
  position: relative;

  & input {
    background-color: #fff;
    border: 1px solid #eee7e7;
    border-radius: 32px;
    color: #595959;
    height: 100%;
    max-width: 400px;
    outline: none;
    padding: 10px 60px 10px 26px;
    width: 100%;
  }

  & img {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }
}