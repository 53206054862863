@mixin btn {
  line-height: normal;
  width: 90px;
  min-height: 2.5rem;
}

.settlements {
  &__nav {
    &-item {
      padding: 5px 10px;
      cursor: pointer;
      color: $mainDecorColor;
      border-left: 1px solid $mainDecorColor;
      border-top: 1px solid $mainDecorColor;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &-item:nth-child(5) {
      border-right: 1px solid $mainDecorColor;
    }

    &-activeItem.MuiButton-root {
      color: #ffffff;
      cursor: default;
      background-color: $mainDecorColor;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80vw;
      max-width: 700px;

      &__text {
        padding: 16px;
        flex-basis: 30%;
        border-bottom: 1px solid rgba(0, 0, 0, .12);
      }

      &__status {
        padding: 16px;
        flex-basis: 50%;
      }

      &__button {
        &--blue.MuiButton-root {
          background-color: #337ab7;
          @include btn
        }

        &--green.MuiButton-root {
          background-color: #228b21;
          @include btn
        }

        &--red.MuiButton-root {
          background-color: #D32F2F;
          @include btn
        }

        &--grey.MuiButton-root {
          background-color: #aaa;
          @include btn
        }

        &--greyDisabled.MuiButton-root {
          background-color: #aaa;
          pointer-events: none;
          @include btn
        }
      }
    }
  }

  &__addTitle.MuiTypography-root {
    font-size: 1.7rem;
    @media (min-width: 1200px) {
      font-size: 2.1rem;
    }
  }

  &__addBox {
    max-width: 68rem;
    width: 100%;
    padding: 0 2rem;
    @media (min-width: 1200px) {
      padding: 1rem 4.5rem;
    }
  }

  &__tableRow {
    &--white.MuiTableRow-root {
      background-color: white !important;
    }
  }
}
