.mainDash {

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $mainDecorColor;
    color: white;
    height: 120px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 20px;
  }
}