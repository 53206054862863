@import './presets/colors';
@import './presets/dimensions';

.app {
  display: flex;
  height: 100vh;
  flex-direction: row;
  overflow-x: hidden;

  &__leftSide {
    background-color: $mainDecorColor;
    max-width: $sideBarWidth;
  }

  &__rightSide {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: calc(100% - #{$sideBarWidth});
    max-width: 100%;
  }
}

svg {
  path {
    stroke: #ffffff;
  }
}