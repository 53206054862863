.customModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  @include flexAlignItem(center, center);
  z-index: 5000;

  &__addMenu {
    padding: 20px;
    max-width: 90vw;
    min-width: 600px;
    max-height: 90vh;
    overflow: hidden auto;
    border: 1px solid $mainDecorColor;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    @include flexAlignItems(column, nowrap, flex-start, flex-start);

    form {
      min-width: 550px;
      //padding-left: 10px;
      @include flexAlignItems(column, nowrap, flex-start, flex-start);
    }

    &-buttonArea {
      align-self: center;
      width: 80%;
      min-width: 250px;
      margin: 16px 0;
      max-width: 600px;
      @include flexAlignItems(row, nowrap, space-between, center);
    }
  }


  &__scrollArea {
    max-height: calc(80vh - 150px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__content {
    padding: 40px;
    max-width: 550px;
    border: 1px solid $mainDecorColor;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      margin-bottom: 20px;
      @include font($mainDecorColor, 700, 25px, 25px);
    }

    &-errorMessageArea {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;

      p {
        @include font($mainDecorColor, 400, 20px, 20px);
      }

      p:first-child {
        font-weight: 700;
        margin-right: 10px;
      }
    }
  }
}

.alignButton {
  justify-content: center;
}

.addUserModal {
  width: 100%;
}

.dropZone {
  width: 100%;
  height: 100%;
  min-height: 56px;
  max-height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 5px 20px 5px;

  ol {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 10px;

    li:nth-child(2n) {
      background-color: lightblue;
    }

    li {
      display: flex;
      align-items: center;
      width: 100%;
      height: 25px;
      @include font($mainDecorColor, 400, 16px, 22px);

      span {
        width: 20px;
      }

      p {
        width: 320px;
      }

      img {
        width: 12px;
        height: 16px;
        margin-left: 30px;
        cursor: pointer;
      }
    }
  }

  & > label {
    flex-shrink: 0;
  }

}

.dropZone.activeZone {
  border-color: $mainDecorColor;
}

.addFile-label {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: $mainDecorColor;
  background-image: url('../../../../assets/img/ui/plus.png');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;

  &:after {
    content: 'wybierz PDF';
    position: absolute;
    width: 100px;
    @include font($mainDecorColor, 700, 16px, 18px);
    top: 30px;
    left: -32px;
  }

  &__image {
    &:after {
      content: 'Dodaj obraz produktu';
      width: 155px;
      left: -65px;
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}


.addFile-input {
  display: none;
}

.errorModal {
  z-index: 10000;
}