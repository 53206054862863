.addUserTable {
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  width: fit-content;
}

.addUserTable button {
  background: none;
  border: none;
  cursor: pointer;
}

.addUserTable button:focus {
  outline: black solid 1px;
}

.edit {
  margin-top: 12px;
}