.reckonings {
  &__actionButton {
    &__header.MuiTypography-root {
      font-size: 1rem;
      font-weight: 700;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: 20px;
      padding-bottom: 6px;
      @media (min-width: 1200px) {
        font-size: 1.2rem;
      }
    }
  }


}